/**
 * Fabrik Framework
 * @root structure
*/

@import 'partials/_variables.scss';

*,
*:after,
*:before {
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

html {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.cf:before, .cf:after, .row:before,
.row:after {
  content: " ";
  display: table;
}

.cf:after, .row:after {
  clear: both;
}

body {

}

  .wrapper {
    margin: 0 auto;
    max-width: 1030px;
    width: 90%;

    img {
      height: auto;
      max-width: 100%;
      vertical-align: middle;
    }
  }

  header.main {
    background: #fff;
    text-transform: uppercase;
    font-size: 1.2rem;
    text-align: center;

    @media (--l) {
      text-align: left;
    }

    .header__top{
      background-color: #646363;
      padding: 1em 0;
      margin-bottom: 3em;

      li, a{
        color: white;
      }
    }

    li, a {
      color: #646464;
      display: inline-block;
    }

    ul {
      margin: 0;
      padding: 0;
      list-style: none;
    }

    .quicklinks {
      color: white;

      [data-lang="fi_fi"],
      [data-lang="en_en"] {
        text-decoration: underline;
      }
    }

    .col--text-right {

      @media (--l) {
        text-align: right;
      }

      ul {
        display: inline-block;
      }

      li {
        margin-left: 1.5rem;
      }
    }

    .cart__count {
      display: inline-block;
      font-size: 1.4rem;
      margin-top: 2rem;

      @media (--l) {

        margin-top: 0;

        &::before {
          display: inline-block;
          content: '|';
          margin: 0 1rem;
        }
      }
    }

    .logo {
      margin-bottom: 2rem;
      text-align: center;
    }

    nav {
      text-align: center;
      margin-bottom: 3em;

      li {

        display: block;
        margin-bottom: 1rem;

        @media (--l) {

          margin-bottom: 0;
          display: inline-block;

          &::after {
            content: '';
            display: inline-block;
            width: 30px;
            height: 1px;
            border-top: 2px solid white;
            margin: 0 1rem;
            vertical-align: 3px;
          }
        }

        &:last-child::after {
          display: none;
        }

        &.Current a {
          color: $green;
        }
      }

      a {

        font-size: 1.4rem;

        &:hover {
          color: $green;
        }

      }

    }
  }

  nav.sub {

    border-bottom: 1px solid $lightGrey;
    margin-bottom: 2rem;
    padding-bottom: 1rem;

    @media (--l) {
      border: none;
    }

    ul {
      list-style: none;
      padding: 0;
      margin: 0;
    }

    li {
      margin-bottom: 1rem;
      font-size: 1.4rem;
      display: inline-block;

      @media (--l) {
        display: block;
      }

      & a::before {
        content: '・';
        display: inline-block;
        margin-right: .4rem;
      }

      &.Current a {
        color: $green;
      }
    }

    a {
      color: $baseFontColor;
    }
  }

  /* Mainpic */

  .mainpic{
    background-size: cover;
    background-position: center top;

    &__content{
      padding: 10em 0;
      color: #fff;
      max-width: 65rem;
      text-align: center;
      margin: 0 auto;

      a{
        color: #fff;
        font-weight: bold;
        text-transform: uppercase;

        &:after{
          content: '>';
          color: #ef7500;
          top: 5px;
          margin-left: .25em;
        }
      }
    }
  }


  /* Lift */

  .lift-boxes{
    background-clip: content-box;
    margin-top: 2em;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 46.5rem;
    position: relative;
    z-index: 1;
    border-left: 5px solid white;

    &:first-child{
      border: none;
    }

    .lift__header{
      line-height: 1.6;

      &, a{
        color: #fff;
      }

      h2, .h2{
        font-size: 2.6rem;
      }

    }

  }

  .lift__header {
    padding: 3rem 0;
    font-size: 1.4rem;
    margin-bottom: 2rem;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;

    h2, .h2{
      font-size: 2.2rem;
    }

    &, a {
      color: #333;
    }

    &__container {
      text-align: center;
      padding: 1em;

      a{
        background-color: $orange;
        padding: .5em 1.5em;
        display: inline-block;
        position: absolute;
        z-index: 2;
        bottom: 2em;
        left: 0;
        right: 0;
        margin: 0 auto;
        max-width: 19.1rem;
      }

      .wrapper {
        @media (--l) {
          width: 50%;
        }
      }
    }

    &--text-black {
      h2 {
        color: black;
        text-shadow: none;
      }
    }

    /* Category lifts */
    &--category {
      display: block;
      padding: 3rem;

      @media (--l) {
        padding: 4.5rem 3rem;
      }

      .lift__header__container {
        position: relative;
        min-height: 20rem;

        @media (--l) {
          min-height: 30rem;
        }
      }

      .button {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        margin: 0 auto;
        width: 50%;
        font-weight: 700;
        text-shadow: none;
      }
    }

    /* Campaign lift */
    &--campaign {
      background-repeat: no-repeat;
      display: block;
      text-align: center;
      padding: 2rem;
      text-transform: none;

      @media (--l) {
        font-size: 2.2rem;
        padding: 5rem 0;
      }

      h2, .h2 {
        text-transform: uppercase;

        @media (--l) {
          font-size: 4rem;
        }
      }

      .lift__header__container {
        @media (--l) {
          width: 70%;
          margin: 0 auto;
        }
      }

      .button {
        background: none;
        padding: 0;
        font-size: 1.4rem;
        text-transform: uppercase;
      }
    }
  }

  .product__list {

    &--new {
      h2, .h2 {
        text-align: center;
        color: white;
        text-transform: uppercase;
        padding: 4rem 0;
      }
    }
  }

  .product__item {

    font-size: 1.8rem;
    margin-bottom: 3rem;

    @media (--l) {
      margin-bottom: 0;
    }

    img {
      display: block;
      margin-bottom: 2rem;
    }

    .text-link {
      color: $baseFontColor;
    }

    h3 {
      font-weight: 700;
      font-size: 1.8rem;
    }

    &__header p {
      font-size: 1.4rem;
    }

    .Button {
      background: $green;
      color: white;
      text-transform: uppercase;
      border-radius: 0;
      border: none;
      padding: 1rem;
      box-shadow: none;
      text-shadow: none;
      font-weight: 700;
      text-align: center;
      font-size: 1.4rem;
    }
  }

  .front-lifts{
    margin-top: -3.5em;
  }

  .category-list-item{
    margin-bottom: 1em;
    display: block;

    &, a{
      color: #333;
    }

    p{
      font-size: 1.4rem;

      @media (--m) {
        font-size: 1.6rem;
      }
    }

    h2, .h2{
      font-size: 1.8rem;

      @media (--m) {
        font-size: 2.2rem;
      }
    }
  }

  .tab {
    margin-bottom: 3rem;

    &__buttons {
      border-bottom: 1px solid #c5c5c5;
      padding-bottom: 1rem;
      margin-bottom: 4rem;
      text-align: center;

      a {
        color: #888;
        display: inline-block;
        font-size: 1.6rem;
        padding: .5em .7em .7em;

        &.current {
          color: $green;
        }
      }
    }

    &__container{
      display: none;

      &.current{
        display: block;
      }
    }

  }

  footer {
    background: #e9e9e9;
    padding: 4rem 0;
    color: #666565;

    a {
      color: $orange;
    }

    .footer2{
      color: #333;
      font-weight: normal;

      a{
        color: #333;
        &:hover{
          opacity: .8;
        }
      }
    }

    ul {
      margin: 0;
      padding: 0;
      list-style: none;

      /* ul follows after ul */
      & + ul {
        margin-top: 2rem;
      }
    }

    .row {
      [class*="col"] {

        margin-bottom: 2rem;
        font-size: 1.6rem;
        line-height: 1.3;

        &:first-child {
          text-align: center;

          @media (--l) {
            text-align: left;
          }
        }

        &:last-child {
          a {
            color: #8b8b8b;
          }
        }
      }
    }
  }

#MiniCartWrapper {

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  .CartProductRemove {
    font-size: 1.4rem;
  }

  .button--border {
    margin: 2rem 0;
    display: inline-block;
  }

  .button--wide {
    font-size: 1.8rem;
    padding: 1rem;
  }

  dl {
    margin: 0;
    margin-bottom: 2rem;

    dd, dt {
      margin: 0;
      padding: 0;
    }
  }

  h2 {
    text-transform: uppercase;
  }

  h3 {
    font-size: 1.8rem;
    margin-bottom: 0;
  }
}

/*
  ContactForm
*/

#ContactForm{
  label{
    display: block;
  }
  button[type="submit"]{
    display: inline-block;
    margin-top: 1em;
  }
}

/*
  Search
*/

#SearchForm {
  margin-top: 1rem;
  margin-bottom: 2rem;
  text-align: center;

  label {
    display: none;
  }

  input {
    border: 2px solid #c5c5c5;
    padding: 1rem;
    width: 50%;
    display: inline-block;
  }

  button {
    border: none;
    border-radius: 0;
    text-transform: uppercase;
    color: #fff;
    background: $orange;
    padding: 1rem 2rem;
    margin: 0 auto;
    display: inline-block;
  }
}
